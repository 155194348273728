*{
  box-sizing: border-box;
  max-width: 100vw;
}

body{
  min-width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  background: #f1f1f1;
}

body *{
  font-family: 'Lato', sans-serif;
}
h1,h2{
  letter-spacing: 0.08em;
}
h3,h4,h5,h6,p{
  letter-spacing: 0.06em;
}

.App{
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  justify-content: space-between;
}

.PageHeader{
  display: none;
}

.PageFooter{
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar{
  width: 5px;
  height: 100%;
}
::-webkit-scrollbar-track{
  width: 5px;
  background: #93BFB7;;
}

::-webkit-scrollbar-thumb{
  width: 5px;
  background: #2D3E40;
  border-radius: 7px;
}

@media screen and (min-width: 1000px){
  .Tablet_Less{
    display: none;  
  }
}